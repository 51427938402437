import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CustomDatePicker = ({ value, onChange, error, helperText, name, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={dayjs(value)}
        onChange={onChange}
        renderInput={(params) => <input {...params.inputProps} />}
        placeholder="dd/mm/yyyy"
        slotProps={{
          textField: {
            error: error,
            helperText: helperText,
            fullWidth: true,
            name: name
          }
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;