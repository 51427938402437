import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Sidebar } from './Sidebar';
import { Box } from '@mui/material';
import Routes from '../routes/Routes';

const Layout = () => {
  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box sx={{ overflow: 'hidden', flexGrow: 1 }}>
          <Routes />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Layout;
