import { useState } from 'react';
import {
  People as PeopleIcon,
  HealthAndSafety as HealthIcon,
  Gavel as LegalIcon,
  AccountBalance as AccountingIcon,
  AttachMoney as FinanceIcon,
  Assignment as ProjectsIcon,
  LocalShipping as TransportIcon,
  ShoppingCart as PurchaseIcon,
  LinearScale as SupplyChainIcon,
  Computer as ITIcon,
  Description as TendersIcon,
} from '@mui/icons-material';

const useResources = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const departments = [
    {
      name: 'Compras',
      description: 'Área de de compras',
      icon: <PurchaseIcon />,
      links: [
        { title: 'Solicitudes de compra', url: '', isExternal: false, description: 'Sistema de solicitudes de compra' },
        { title: 'Proveedores', url: '', isExternal: false, description: 'Directorio de proveedores' },
        { title: 'Colombia Compra Eficiente', url: '', isExternal: true, description: 'Portal de Colombia Compra Eficiente' },
      ]
    },
    {
      name: 'Contabilidad',
      description: 'Área de contabilidad',
      icon: <AccountingIcon />,
      links: [
        { title: 'Plan contable', url: '', isExternal: false, description: 'Plan contable de la empresa' },
        { title: 'Informes financieros', url: '', isExternal: false, description: 'Informes financieros mensuales' },
        { title: 'DIAN', url: '', isExternal: true, description: 'Dirección de Impuestos y Aduanas Nacionales' },
      ]
    },
    {
      name: 'Financiera',
      description: 'Área financiera',
      icon: <FinanceIcon />,
      links: [
        { title: 'Presupuestos', url: '', isExternal: false, description: 'Gestión de presupuestos' },
        { title: 'Tesorería', url: '', isExternal: false, description: 'Portal de tesorería' },
        { title: 'Superintendencia Financiera', url: '', isExternal: true, description: 'Superintendencia Financiera de Colombia' },
      ]
    },
    {
      name: 'Gestión Humana',
      description: 'Área de gestión humana',
      icon: <PeopleIcon />,
      links: [
        { title: 'Portal de empleados', url: '', isExternal: false, description: 'Acceso al portal interno de empleados' },
        { title: 'Manual de funciones', url: '', isExternal: false, description: 'Documento con descripción de cargos y funciones' },
        { title: 'Ministerio de Trabajo', url: '', isExternal: true, description: 'Sitio oficial del Ministerio de Trabajo' },
      ]
    },
    {
      name: 'Jurídica',
      description: 'Área jurídica',
      icon: <LegalIcon />,
      links: [
        { title: 'Contratos', url: '', isExternal: false, description: 'Repositorio de contratos' },
        { title: 'Normatividad', url: '', isExternal: false, description: 'Normatividad aplicable' },
        { title: 'Superintendencia de Sociedades', url: '', isExternal: true, description: 'Superintendencia de Sociedades' },
      ]
    },
    {
      name: 'Licitaciones',
      description: 'Área de licitaciones',
      icon: <TendersIcon />,
      links: [
        { title: 'Licitaciones activas', url: '', isExternal: false, description: 'Licitaciones en curso' },
        { title: 'Histórico', url: '', isExternal: false, description: 'Histórico de licitaciones' },
        { title: 'SECOP', url: '', isExternal: true, description: 'Sistema Electrónico de Contratación Pública' },
      ]
    },
    {
      name: 'Proyectos',
      description: 'Área de proyectos',
      icon: <ProjectsIcon />,
      links: [
        { title: 'Gestión de proyectos', url: '', isExternal: false, description: 'Sistema de gestión de proyectos' },
        { title: 'Cronogramas', url: '', isExternal: false, description: 'Cronogramas de proyectos activos' },
        { title: 'PMI', url: '', isExternal: true, description: 'Project Management Institute' },
      ]
    },
    {
      name: 'SSTA',
      description: 'Área de Seguridad y Salud en el Trabajo y Ambiental',
      icon: <HealthIcon />,
      links: [
        { title: 'Protocolos de seguridad', url: '', isExternal: false, description: 'Documentos de protocolos de seguridad' },
        { title: 'Reportes de incidentes', url: '', isExternal: false, description: 'Sistema de reporte de incidentes' },
        { title: 'Ministerio de Ambiente', url: '', isExternal: true, description: 'Sitio oficial del Ministerio de Ambiente' },
      ]
    },
    {
      name: 'Suministros',
      description: 'Área de cadena de suministros',
      icon: <SupplyChainIcon />,
      links: [
        { title: 'Inventarios', url: '', isExternal: false, description: 'Sistema de gestión de inventarios' },
        { title: 'Logística', url: '', isExternal: false, description: 'Operaciones logísticas' },
        { title: 'CSCMP', url: '', isExternal: true, description: 'Council of Supply Chain Management Professionals' },
      ]
    },
    {
      name: 'TI',
      description: 'Área de Infraestructura Tecnológica',
      icon: <ITIcon />,
      links: [
        { title: 'Tickets Infraestructura', isExternal: true, description: 'Formulario para crear un ticket de soporte', url: 'https://eliteingenierosas.sharepoint.com/:l:/s/TICKETSINFRAESTRUCTURATECNOLGICAELITEINGENIEROS/FJc3t9sGBqhIkBznDKtPOCYBFK_aDv6wI4yqGkabqAslVg?nav=MTQ5NmRkM2MtZWFkNi00OWUzLWJhZjUtZTAxMjhlZmZjMWY2' },
        { title: 'Proyectos Unidad de TI', isExternal: true, description: 'Propuestas de proyectos del área de TI', url: 'https://eliteingenierosas.sharepoint.com/sites/ProyectosUnidaddeTI/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FProyectosUnidaddeTI%2FShared%20Documents%2FPropuesta%20de%20proyectos&p=true&ga=1' },
        { title: 'Moodle', url: 'http://192.168.100.158/moodle', isExternal: true, description: 'Portal de Moodle' },
        { title: 'Blog', description: 'Blog - Unida TI', isExternal: true, url: 'https://eliteingenierosas.sharepoint.com/:u:/s/ProyectosUnidaddeTI/EaHOVw9mIlVErU2A8p9P-8sBhNdQJVTBFCJ2fPjTWq1DeA?e=mhy6xj' },
      ]
    },
    {
      name: 'Transportes',
      description: 'Área de transporte',
      icon: <TransportIcon />,
      links: [
        { title: 'Flota vehicular', url: '', isExternal: false, description: 'Gestión de flota vehicular' },
        { title: 'Mantenimiento', url: '', isExternal: false, description: 'Programación de mantenimientos' },
        { title: 'Ministerio de Transporte', url: '', isExternal: true, description: 'Ministerio de Transporte' },
      ]
    }
  ];
  return {
    tabValue,
    handleTabChange,
    departments
  }
}

export default useResources;
