import useResources from '../controllers/viewsControllers/resources';

import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Tabs,
  Tab,
  Paper,
  Divider,
} from '@mui/material';
import { Link as LinkIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';



const Resources = () => {
  const { tabValue, departments, handleTabChange } = useResources();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Typography variant="h4" marginBottom={2} color="primary">
          Enlaces de interés
        </Typography>

        <Box sx={{ width: '100%' }}>
          <Paper elevation={3} sx={{ mb: 3 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="departamentos"
            >
              {departments.map((dept, index) => (
                <Tab
                  key={dept.name}
                  icon={dept.icon}
                  label={dept.name}
                  id={`tab-${index}`}
                  aria-controls={`tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </Paper>

          {departments.map((dept, index) => (
            <div
              key={dept.name}
              role="tabpanel"
              hidden={tabValue !== index}
              id={`tabpanel-${index}`}
              aria-labelledby={`tab-${index}`}
            >
              {tabValue === index && (
                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'center' }}>
                    <Box sx={{ mr: 1, color: 'primary.main', display: 'flex' }}>{dept.icon}</Box>
                    <Typography variant="h6">{dept.description}</Typography>
                  </Box>

                  <Divider sx={{ mb: 2 }} />

                  <List>
                    {dept.links.map((link, linkIndex) => (
                      <ListItem
                        key={link.title}
                        component="a"
                        href={link.url}
                        target={link.isExternal ? "_blank" : "_self"}
                        rel={link.isExternal ? "noopener noreferrer" : ""}
                        sx={{
                          borderRadius: 1,
                          mb: 1,
                          '&:hover': {
                            bgcolor: 'action.hover',
                          }
                        }}
                      >
                        <ListItemIcon>
                          {link.isExternal ?
                            <OpenInNewIcon color="primary" /> : <LinkIcon color="primary" />
                          }
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2" style={{ color: '#FFFFFF' }}>
                              {link.title}
                            </Typography>
                          }
                          secondary={link.description}
                        />

                        <Chip
                          size="small"
                          label={link.isExternal ? 'Externo' : 'Interno'}
                          sx={{ "background": "rgba(255, 255, 255, 0.08)" }}
                          variant="outlined"
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </div>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Resources;