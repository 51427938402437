import React from 'react';
import { Avatar, Box, Button, Paper, Container, FormControl, MenuItem, InputLabel, Select, Typography } from '@mui/material';
import useComponent from '../../components';
import useUserEdit from '../../controllers/viewsControllers/user/userEdit';
import { CustomLink } from '../../components/CustomLink';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Person as PersonIcon,
  Folder as FolderIcon,
} from "@mui/icons-material"

const EditUser = () => {
  const {
    positions,
    isEditMode,
    imagePreview,
    control,
    activeTab,
    setActiveTab,
    data,
    user,
    watch,
    register,
    setValueData,
    handleImageChange,
    handleSubmit,
    onSubmit,
  } = useUserEdit();

  const { CustomDatePicker, CustomUserSelect, CustomRoleSelect, CustomWorkLocationSelect, CustomInput, CustomCheckBox } = useComponent();

  if (!user) return;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }


  return (

    <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
      <Typography variant="h4" marginBottom={2} color="primary">
        {isEditMode ? 'Editar usuario' : 'Crear usuario'}
      </Typography>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <CustomLink to="/admin/usuarios" isActive={false} textDecoration='underline' sx={{ mb: 2 }}>
          <ChevronLeftIcon /> Regresar
        </CustomLink>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Paper elevation={3} sx={{ mb: 3 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              mb: 3,
              "& .MuiTabs-indicator": {
                backgroundColor: "#4caf50",
              },
            }}
          >
            <Tab
              icon={<PersonIcon />}
              label="Información"
              sx={{
                color: "white",
                "&.Mui-selected": {
                  color: "#4caf50",
                },
              }}
            />
            <Tab
              icon={<FolderIcon />}
              label="Documentos"
              sx={{
                color: "white",
                "&.Mui-selected": {
                  color: "#4caf50",
                },
              }}
            />
          </Tabs>
        </Paper>
      </Box>
      {activeTab === 1 ? (
        // <DocumentManagement />
        <span>En progreso</span>
      ) : (

        <Box sx={{ width: '100%' }}>
          <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar
                    src={imagePreview}
                    alt={`${user?.first_name} ${user?.last_name}`}
                    sx={{ width: 100, height: 100, border: '1px solid white', borderRadius: '50%' }}
                  />
                  <Button variant="contained" component="label">
                    {isEditMode ? 'Cambiar foto' : 'Subir foto'}
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </Button>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomDatePicker
                  label="Fecha de contratación"
                  value={watch("hire_date")}
                  onChange={(value) => setValueData('hire_date', value)}
                  name="hire_date"
                  control={control}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomRoleSelect
                  label="Roles"
                  value={watch("role_id") || user?.role_id || ""}
                  onChange={(value) => setValueData('role_id', value)}
                  name="role_id"
                  control={control}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomWorkLocationSelect
                  label="Lugar de trabajo"
                  value={watch("work_location_id") || data?.work_location_id || ""}
                  onChange={(value) => setValueData('work_location_id', value)}
                  name="work_location_id"
                  control={control}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>Seleccione una posición</InputLabel>
                  <Select
                    label="Seleccione una posición"
                    name="position"
                    value={watch("position") || user?.position}
                    onChange={(e) => setValueData('position', e.target.value)}
                    sx={{ borderRadius: '8px' }}
                  >
                    {positions.map((position) => (
                      <MenuItem key={position} value={position}>
                        {position}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomInput
                  label="Número de documento"
                  type="number"
                  value={watch("id")}
                  ref={register}
                  onChange={(value) => setValueData('id', value)}
                  fullWidth
                  name="id"
                  control={control}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomInput
                  label="Nombres"
                  type="text"
                  name="first_name"
                  value={watch("first_name")}
                  {...register('first_name')}
                  onChange={(value) => setValueData('first_name', value)}
                  fullWidth
                  control={control}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomInput
                  label="Apellidos"
                  type="text"
                  name="last_name"
                  value={watch("last_name")}
                  {...register('last_name')}
                  onChange={(value) => setValueData('last_name', value)}
                  fullWidth
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomInput
                  label="Correo electrónico empresarial"
                  type="email"
                  name="email"
                  value={watch("email")}
                  {...register('email')}
                  onChange={(value) => setValueData('email', value)}
                  fullWidth
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomInput
                  label="Correo electrónico personal"
                  type="email"
                  name="email"
                  value={watch("personal_email")}
                  {...register('personal_email')}
                  onChange={(value) => setValueData('personal_email', value)}
                  fullWidth
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomInput
                  label="Dirección de residencia"
                  type="text"
                  name="address"
                  value={watch("address")}
                  {...register('address')}
                  onChange={(value) => setValueData('address', value)}
                  fullWidth
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomInput
                  label="Telefóno"
                  type="number"
                  name="phone"
                  value={watch("phone")}
                  {...register('phone')}
                  onChange={(value) => setValueData('phone', value)}
                  fullWidth
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomUserSelect
                  label="Supervisor"
                  value={watch("immediate_supervisor") || user?.immediate_supervisor}
                  name="immediate_supervisor"
                  ref={register}
                  onChange={(value) => setValueData('immediate_supervisor', value)}
                  control={control}
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <CustomCheckBox
                  label="Activo"
                  checked={user?.is_active}
                  value={data?.is_active}
                  isDisable={!user?.email}
                  ref={register}
                  control={control}
                  name="is_active"
                />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: '#4caf50',
                    '&:hover': {
                      backgroundColor: '#45a049',
                    },
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </form>
          </Paper>
        </Box>
      )}
    </Container>
  );
}

export default EditUser;