import React from 'react'
import {
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  TableCell,
  MenuItem
} from '@mui/material';
import useNominaProject from '../controllers/viewsControllers/nominaProjects';
import useComponent from '../components';
import "dayjs/locale/es";
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
}))

const NominaProjects = () => {
  const {
    data,
    control,
    register,
    projects,
    sitesByProject,
    setSelectedSite,
    selectedProject,
    selectedSite,
    handleFieldChange,
    setSelectedProject,
    selectedDate,
    setSelectedDate,
  } = useNominaProject();
  const { CustomInput, CustomCalendar, CustomCheckBox } = useComponent();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
        <Typography variant="h4" marginBottom={2} color="primary">
          Nómina por proyecto
        </Typography>

        <Box sx={{ mt: 4, p: 2 }}>
          <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>


            <Typography variant="h6" color="primary">
              Proyecto
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="crew-dropdown">Seleccionar proyecto</InputLabel>
              <Select
                label="Seleccionar proyecto"
                labelId="crew-dropdown"
                value={selectedProject}
                onChange={(e) => handleFieldChange({
                  value: e.target.value,
                  setValue: setSelectedProject,
                })}
                sx={{ borderRadius: 2 }}
              >
                {
                  projects.map((project) => (
                    <MenuItem key={project.id} value={project.id} >{project.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="crew-dropdown">Seleccionar sitio</InputLabel>
              <Select
                label="Seleccionar sede"
                disabled={!selectedProject || !sitesByProject || sitesByProject.length === 0}
                labelId="crew-dropdown"
                value={selectedSite}
                onChange={(e) => handleFieldChange({
                  value: e.target.value,
                  setValue: setSelectedSite,
                })}
                sx={{ borderRadius: 2 }}
              >
                {
                  sitesByProject?.map((site) => (
                    <MenuItem key={site.id} value={site.id} >{site.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>


            {
              selectedSite && (
                <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', margin: 4 }}>
                  <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', p: 2 }}>
                    <CustomCalendar setSelectedDate={setSelectedDate} />
                  </Paper>
                </Box>
              )
            }

          </Paper>
        </Box>

        {
          selectedDate && (
            <Box sx={{ mt: 4, p: 2 }}>
              <Paper elevation={3} sx={{ maxWidth: '100%', mx: 'auto', mb: 4, p: 2 }}>
                <Typography variant="h6" color="primary" marginBottom={4}>
                  {`Nómina de: ${selectedDate}`}
                </Typography>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell>Novedad</StyledTableCell>
                        <StyledTableCell>Hora de entrada</StyledTableCell>
                        <StyledTableCell>Hora de salida</StyledTableCell>
                        <StyledTableCell>Hora real de salida</StyledTableCell>
                        <StyledTableCell>Tiempo de descanso</StyledTableCell>
                        <StyledTableCell>Observaciones</StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CustomCheckBox
                              isDisabled={false}
                              ref={register}
                              control={control}
                              name="checkAll"
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Select
                            value={17}
                            sx={{ width: '100%' }}
                            onChange={() => { }}
                          >
                            <MenuItem value={17}>Sin novedad</MenuItem>
                            <MenuItem value={20}>Calamidad doméstica</MenuItem>
                            <MenuItem value={30}>Incapacidad</MenuItem>
                            <MenuItem value={50}>Horas extras</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <CustomInput
                            label="hora de entrada"
                            name="identifier"
                            value={''}
                            onChange={(value) => console.log(value)}
                            fullWidth
                            margin="normal"
                          />
                        </TableCell>
                        <TableCell>
                          <CustomInput
                            label="hora de salida"
                            name="identifier"
                            value={''}
                            onChange={(value) => console.log(value)}
                            fullWidth
                            margin="normal"
                          />
                        </TableCell>
                        <TableCell>
                          <CustomInput
                            label="hora de entrada"
                            name="identifier"
                            value={''}
                            onChange={(value) => console.log(value)}
                            fullWidth
                            margin="normal"
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            sx={{ width: '100%' }}
                            value={1}
                            onChange={() => { }}
                          >
                            <MenuItem value={0.25}>15 minutos</MenuItem>
                            <MenuItem value={0.5}>30 minutos</MenuItem>
                            <MenuItem value={0.75}>45 minutos</MenuItem>
                            <MenuItem value={1}>1 hora</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        data.map((user) => (
                          <TableRow key={user.id}>
                            <TableCell>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CustomCheckBox
                                  isDisabled={false}
                                  ref={register}
                                  control={control}
                                  name="checkAll"
                                />
                                {user.name}
                              </Box>
                            </TableCell>
                            <TableCell>{user.exception}</TableCell>
                            <TableCell>
                              <CustomInput
                                name="horaEntrada"
                                value={user.startHour}
                                onChange={(value) => console.log(value)}
                                fullWidth
                                margin="normal"
                              />
                            </TableCell>
                            <TableCell>
                              <CustomInput
                                name="horaSalida"
                                value={user.endHour}
                                onChange={(value) => console.log(value)}
                                fullWidth
                                margin="normal"
                              />
                            </TableCell>
                            <TableCell>
                              <CustomInput
                                name="horaRealSalida"
                                value={user.realEndHour}
                                onChange={(value) => console.log(value)}
                                fullWidth
                                margin="normal"
                              />
                            </TableCell>
                            <TableCell>
                              <CustomInput
                                name="restHour"
                                value={user.restHour}
                                onChange={(value) => console.log(value)}
                                fullWidth
                                margin="normal"
                              />
                            </TableCell>
                            <TableCell>
                              <CustomInput
                                name="observations"
                                value={''}
                                onChange={(value) => console.log(value)}
                                fullWidth
                                margin="normal"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )
        }
      </Container >
    </Box >
  )
};

export default NominaProjects;