/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import useUserService from '../../../services/user';
import { useNavigate } from 'react-router-dom';

const useUser = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('')
  const [users, setUsers] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [editingId, setEditingId] = useState(null)
  const [editingName, setEditingName] = useState('')
  const { getUsers } = useUserService();

  const handleSearchItem = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleEditStart = (position) => {
    setEditingId(position.id)
    setEditingName(position.first_name)
  }

  const handleEditSave = () => {
    setUsers(users.map(p =>
      p.id === editingId ? { ...p, name: editingName } : p
    ))
    setEditingId(null)
  }

  const handleEdit = (userId = null) => {
    if (!userId) navigate('/admin/usuarios/');

    navigate(`/admin/usuarios/${userId}`)
  }

  const handleCreate = () => navigate(`/admin/usuarios/crear`)

  const handleGetUsers = async (currentPage, positionsPerPage) => {
    const response = await getUsers({ page: currentPage, searchTerm, positionsPerPage });

    setUsers(response.users);
    setTotalPages(response.totalPages);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleGetUsers();
  };

  return {
    users,
    searchTerm,
    editingId,
    editingName,
    totalPages,
    setEditingName,
    handleEditSave,
    handleEditStart,
    handleSearchItem,
    handleGetUsers,
    handleKeyDown,
    handleEdit,
    handleCreate
  }
}

export default useUser