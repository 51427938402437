import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import useComponentsControllers from '../../controllers/componentsControllers';

const CustomWorkLocationSelect = ({
  value,
  onChange,
  error,
  helperText,
  label = "Lugar de trabajo",
  ...props
}) => {
  const { useWorkLocationSelect } = useComponentsControllers();
  const { workLocations, selectedWorkLocation, handleSelectedWorkLocation } = useWorkLocationSelect({
    selectedValue: value,
    onChange
  });

  return (
    <Autocomplete
      options={workLocations}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      value={selectedWorkLocation}
      sx={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          required={props.required}
        />
      )}
      onChange={(event, newValue) => handleSelectedWorkLocation(event, newValue)}
      {...props}
    />
  );
};

export default CustomWorkLocationSelect;