import axios from 'axios';
import useAuth from './auth';

const useWorkLocationsService = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getToken, logout } = useAuth();

  const token = getToken();
  if (!token) logout();

  const getWorkLocations = async ({ page = 1, searchTerm = '', positionsPerPage = 10 }) => {
    try {
      const response = await axios.get(`${apiUrl}/work-location?page=${page}&searchTerm=${searchTerm}&limit=${positionsPerPage}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the work locations:', error);
      return [];
    }
  };

  return {
    getWorkLocations,
  };
}

export default useWorkLocationsService;