import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemButton, IconButton, Box, useTheme, useMediaQuery, Collapse } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import EvStationOutlinedIcon from '@mui/icons-material/EvStationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LinkIcon from '@mui/icons-material/Link';
import { CustomLink } from './CustomLink';
import useAuth from '../services/auth';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SpeedIcon from '@mui/icons-material/Speed';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const location = useLocation();

  const { isAuthenticated, getRole } = useAuth();
  const isAuth = isAuthenticated();
  const role = getRole();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const toggleExpand = (e, index) => {
    e.stopPropagation();
    e.preventDefault();

    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const menuItems = [
    { text: 'Inicio', link: '/', isActive: true, icon: <HomeOutlinedIcon />, roles: [] },
    {
      text: 'Evaluación', isActive: true, icon: <AssessmentIcon />, roles: [],
      subItems: [
        { text: 'Evaluación', link: '/evaluacion', isActive: true, icon: <TableViewOutlinedIcon />, roles: [] },
        { text: 'Seguimiento', link: '/evaluaciones/seguimiento', isActive: true, icon: <TableViewOutlinedIcon />, roles: [] },
      ]
    },
    {
      text: 'KPI', isActive: true, icon: <TrendingUpIcon />,
      subItems: [
        { text: 'Subestaciones', link: '/kpi/subestaciones', isActive: true, icon: <EvStationOutlinedIcon />, roles: [42, 51, 65, 68, 73, 11] },
        { text: 'Seguimiento Subestaciones', link: '/kpi/seguimiento-subestaciones', isActive: true, icon: <SpeedIcon />, roles: [42, 43, 51, 11] },
        { text: 'Alumbrado público', link: '/kpi/alumbrado-publico', isActive: true, icon: <WbIncandescentOutlinedIcon />, roles: [42, 51, 65, 68, 73, 11] },
        { text: 'Seguimiento Alumbrado público', link: '/kpi/seguimiento-alumbrado-publico', isActive: true, icon: <SpeedIcon />, roles: [42, 43, 51, 11] },
      ],
      roles: [42, 43, 51, 65, 68, 73, 11]
    },
    {
      text: 'Nómina', isActive: true, icon: <PermContactCalendarIcon />, roles: [3, 11, 42, 34],
      subItems: [
        { text: 'Nómina', link: '/nomina', isActive: true, icon: <TableViewOutlinedIcon />, roles: [3, 11, 42, 34] },
        { text: 'Proyectos', link: '/nomina/proyectos', isActive: true, icon: <TableViewOutlinedIcon />, roles: [3, 11, 42, 34] },
      ]
    },
    {
      text: 'Administración', isActive: true, icon: <AssessmentIcon />, roles: [11, 42, 34],
      subItems: [
        { text: 'Usuarios', link: '/admin/usuarios', isActive: true, icon: <TableViewOutlinedIcon />, roles: [11, 42, 34] },
        { text: 'Roles', link: '/admin/roles', isActive: true, icon: <TableViewOutlinedIcon />, roles: [11, 42, 34] },
        { text: 'Permisos', link: '/admin/permisos', isActive: true, icon: <TableViewOutlinedIcon />, roles: [11, 42, 34] },
        { text: 'Geocercas', link: '/admin/geocercas', isActive: true, icon: <TableViewOutlinedIcon />, roles: [11, 42, 34] },
      ]
    },
    {
      text: 'Enlaces de interés', link: '/enlaces', isActive: true, icon: <LinkIcon />, roles: [],
    },
  ];

  const isActive = (link) => {
    return matchPath({ path: link, end: false }, location.pathname);
  };

  const isParentActive = (subItems) => {
    return subItems.some(subItem => isActive(subItem.link));
  };

  if (!isAuth) return null;

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', borderRight: '1px solid #575756'
    }}>
      {
        isSmallScreen &&
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{
            position: isSmallScreen ? 'absolute' : 'relative',
            top: 20,
            left: 1,
          }}
        >
          <MenuIcon />
        </IconButton>
      }

      <Drawer
        anchor="left"
        open={isOpen}
        variant={isSmallScreen ? "temporary" : "permanent"}
        onClose={toggleDrawer(false)}
        classes={{
          root: {
            backgroundColor: 'lightblue !important', // Example styles
          }
        }}
        sx={{
          '& .MuiPaper-root.MuiDrawer-paper': {
            position: isSmallScreen ? 'absolute' : 'relative',
            top: 0,
            bottom: 0,
            width: '210px',
            zIndex: '1000',
            overflow: 'hidden',
            border: 'none',
          },
        }} >
        <Box
          sx={{ width: 210, p: 2, backgroundColor: 'background.default', height: '100vh' }}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {
            isSmallScreen &&
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ position: 'absolute', right: 0, top: 0 }}
            >
              <CloseIcon />
            </IconButton>
          }

          <List>
            {menuItems.map((item, index) => (
              <Box key={item.text} sx={{ marginBottom: '0.5rem' }}>
                {
                  item.isActive &&
                  (item.roles.length === 0 || item.roles.includes(role)) &&
                  <>
                    {item.subItems ? (
                      <>
                        <ListItem
                          onClick={(e) => toggleExpand(e, index)}
                          sx={{
                            padding: '0',
                            cursor: 'pointer',
                            color: isParentActive(item.subItems) ? 'primary.main' : 'text.primary'
                          }}
                        >
                          {item.icon}
                          <ListItemText primary={item.text} />
                          {expandedItems[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Collapse in={expandedItems[index]} timeout="auto" unmountOnExit>
                          {item.subItems.map(subItem => (
                            subItem.isActive &&
                            (subItem.roles.length === 0 || subItem.roles.includes(role)) &&
                            <Box key={subItem.text} sx={{ ml: '1rem' }}>
                              <ListItem
                                key={subItem.text}
                                isActive={isActive(subItem.link)}
                                component={CustomLink}
                                to={subItem.link}
                                isBlank={subItem.isBlank}
                              >
                                {subItem.icon}
                                <ListItemText primary={subItem.text} />
                              </ListItem>
                            </Box>
                          ))}
                        </Collapse>
                      </>
                    ) : (
                      <ListItem disablePadding>
                        <ListItemButton style={{ padding: 0 }} onClick={(e) => toggleExpand(e, index)}>
                          {item.icon}
                          <ListItemText primary={item.text} />
                          {expandedItems[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </>
                }
              </Box>
            ))}
          </List>
        </Box>
      </Drawer >
    </Box >
  );
};