import React from 'react';
import { Avatar, Box, Button, Paper, Container, FormControl, MenuItem, InputLabel, Select, Typography } from '@mui/material';
import useComponent from '../../components';
import useUserCreate from '../../controllers/viewsControllers/user/userCreate';
import { CustomLink } from '../../components/CustomLink';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormHelperText from '@mui/material/FormHelperText';

const CreateUser = () => {
  const {
    positions,
    imagePreview,
    control,
    errors,
    data,
    watch,
    setValueData,
    handleImageChange,
    handleSubmit,
    onSubmit,
    isValid,
  } = useUserCreate();

  const { CustomDatePicker, CustomUserSelect, CustomRoleSelect, CustomInput, CustomWorkLocationSelect } = useComponent();

  return (

    <Container maxWidth="xl" sx={{ flexGrow: 1, py: 4 }}>
      <Typography variant="h4" marginBottom={2} color="primary">
        Crear usuario
      </Typography>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <CustomLink to="/admin/usuarios" isActive={false} textDecoration='underline' sx={{ mb: 2 }}>
          <ChevronLeftIcon /> Regresar
        </CustomLink>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pt: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                  sx={{
                    position: 'relative',
                    border: errors.profile_picture ? '2px solid #FF8B00' : 'none',
                    borderRadius: '50%',
                    padding: '2px'
                  }}
                >
                  <Avatar
                    src={imagePreview || watch("profile_picture")}
                    alt={`${watch("first_name") || ""} ${watch("last_name") || ""}`.trim() || "Avatar de usuario"}
                    sx={{
                      width: 100,
                      height: 100,
                      border: '1px solid white',
                      borderRadius: '50%',
                      backgroundColor: '#c5c5c5' // Color de fondo para cuando no hay imagen
                    }}
                  />
                </Box>
                <Button variant="contained" component="label">
                  {data.profile_picture ? 'Cambiar foto' : 'Subir foto'}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
              </Box>
              {errors.profile_picture && (
                <FormHelperText error={true}>
                  {errors.profile_picture.message}
                </FormHelperText>
              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomDatePicker
                label="Fecha de contratación"
                value={watch("hire_date")}
                onChange={(value) => setValueData('hire_date', value)}
                name="hire_date"
                control={control}
                error={!!errors.hire_date}
                helperText={errors.hire_date?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomRoleSelect
                label="Roles"
                value={watch("role_id") || data?.role_id || ""}
                onChange={(value) => setValueData('role_id', value)}
                name="role_id"
                control={control}
                error={!!errors.role_id}
                helperText={errors.role_id?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomWorkLocationSelect
                label="Lugar de trabajo"
                value={watch("work_location_id") || data?.work_location_id || ""}
                onChange={(value) => setValueData('work_location_id', value)}
                name="work_location_id"
                control={control}
                error={!!errors.work_location_id}
                helperText={errors.work_location_id?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <FormControl fullWidth error={!!errors.position}>
                <InputLabel>Seleccione una posición</InputLabel>
                <Select
                  label="Seleccione una posición"
                  name="position"
                  value={watch("position") || data?.position || ""}
                  onChange={(e) => setValueData('position', e.target.value)}
                  sx={{ borderRadius: '8px' }}
                >
                  {positions.map((position) => (
                    <MenuItem key={position} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Select>
                {errors.position && (
                  <FormHelperText>{errors.position.message}</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomInput
                label="Número de documento"
                type="number"
                value={watch("id")}
                onChange={(value) => setValueData('id', value)}
                fullWidth
                name="id"
                control={control}
                error={!!errors.id}
                helperText={errors.id?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomInput
                label="Nombres"
                type="text"
                name="first_name"
                value={watch("first_name")}
                onChange={(value) => setValueData('first_name', value)}
                fullWidth
                control={control}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomInput
                label="Apellidos"
                type="text"
                name="last_name"
                value={watch("last_name")}
                onChange={(value) => setValueData('last_name', value)}
                fullWidth
                control={control}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomInput
                label="Correo electrónico empresarial"
                type="email"
                name="email"
                value={watch("email")}
                onChange={(value) => setValueData('email', value)}
                fullWidth
                control={control}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomInput
                label="Correo electrónico personal"
                type="email"
                name="personal_email"
                value={watch("personal_email")}
                onChange={(value) => setValueData('personal_email', value)}
                fullWidth
                control={control}
                error={!!errors.personal_email}
                helperText={errors.personal_email?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomInput
                label="Dirección de residencia"
                type="text"
                name="address"
                value={watch("address")}
                onChange={(value) => setValueData('address', value)}
                fullWidth
                control={control}
                error={!!errors.address}
                helperText={errors.address?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomInput
                label="Telefóno"
                type="number"
                name="phone"
                value={watch("phone")}
                onChange={(value) => setValueData('phone', value)}
                fullWidth
                control={control}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <CustomUserSelect
                label="Supervisor"
                value={watch("immediate_supervisor") || data?.immediate_supervisor}
                onChange={(value) => setValueData('immediate_supervisor', value)}
                name="immediate_supervisor"
                control={control}
                error={!!errors.immediate_supervisor}
                helperText={errors.immediate_supervisor?.message}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid}
                sx={{
                  backgroundColor: isValid ? '#4caf50' : '#cccccc',
                  '&:hover': {
                    backgroundColor: isValid ? '#45a049' : '#cccccc',
                  },
                }}
              >
                Guardar
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>

    </Container>
  );
}

export default CreateUser;