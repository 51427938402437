import useUser from './user';
import useUserEdit from './userEdit';
import useUserCreate from './userCreate';

const useUserHandlers = () => {
  return {
    useUser,
    useUserEdit,
    useUserCreate
  };
};

export default useUserHandlers;