import { useState, useEffect } from "react";
import useUserService from '../../../services/user';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const useUserEdit = () => {
  const { id } = useParams(); // Obtener el ID de la URL
  const navigate = useNavigate();
  const MAX_FILE_SIZE_KB = 1000;

  // Form
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
      first_name: "",
      hire_date: "",
      id: "",
      immediate_supervisor: "",
      is_active: "",
      last_name: "",
      position: "",
      role_id: "",
      work_location_id: "",
      profile_picture: "",
      address: "",
      personal_email: "",
      phone: ""
    },
  });

  //states
  const [user, setUser] = useState(null);
  const [isEditMode, setIsEditMode] = useState(!!user);
  const [imagePreview, setImagePreview] = useState(user?.profile_picture || '');
  const [data, setData] = useState(getValues());
  const [activeTab, setActiveTab] = useState(0)

  const { uploadImage, getUserProfileImage, updateUser, getUserById } = useUserService();

  const positions = [
    "ADMINISTRATIVO-PROYECTOS",
    "OPERATIVO",
    "ADMINISTRATIVO",
  ];

  const handleUploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    const imageResponse = await uploadImage(formData, user?.id);
    if (imageResponse) {
      setValueData('profile_picture', imageResponse.key);
    } else {
      Swal.fire({
        title: '¡Error!',
        text: 'No se pudo cargar la imagen de perfil',
        icon: 'error',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  const setValueData = (name, value) => {
    setValue(name, value);
    setData((prev) => ({ ...prev, [name]: value }));
  }

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validar tamaño del archivo original
      const fileSizeMB = (file.size / 1024).toFixed(2);
      if (fileSizeMB > MAX_FILE_SIZE_KB) {
        Swal.fire({
          title: '¡Atención!',
          text: `El archivo seleccionado excede el tamaño máximo permitido (1 MB).`,
          icon: 'warning',
          confirmButtonColor: '#6dad48',
          confirmButtonText: 'Aceptar',
        });

        return;
      }

      // Validar que el archivo sea una imagen
      if (!file.type.startsWith('image/')) {
        Swal.fire({
          title: '¡Atención!',
          text: "El archivo seleccionado no es una imagen.",
          icon: 'warning',
          confirmButtonColor: '#6dad48',
          confirmButtonText: 'Aceptar',
        });
        return;
      }

      // Crear una previsualización de la imagen
      const reader = new FileReader();
      reader.onload = (event) => {
        setImagePreview(event.target.result);
      };
      reader.readAsDataURL(file);

      handleUploadImage(file);
    }
  };

  const onSubmit = async (data) => {
    if (!data) return;

    if (typeof data.immediate_supervisor === 'object') {
      data.immediate_supervisor = data.immediate_supervisor.id;
    }

    if (typeof data.role_id === 'object') {
      data.role_id = data.role_id.id;
    }

    if (typeof data.work_location_id === 'object') {
      data.work_location_id = data.work_location_id.id;
    }

    const response = await updateUser(data);

    if (response?.status === 200) {

      Swal.fire({
        title: '¡Éxito!',
        text: response.data.message,
        icon: 'success',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
      });

    } else {
      Swal.fire({
        title: '¡Error!',
        text: response.data.error,
        icon: 'error',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  const handleGetUserById = async (id) => {
    const user = await getUserById(id);
    if (!user) {
      navigate('/admin/usuarios', { replace: true });
      return;
    }
    if (user?.profile_picture) handleGetProfilePicture(user.profile_picture)
    reset(user);
    setUser(user);
    setIsEditMode(true);
  }

  const handleGetProfilePicture = async (key) => {
    try {
      if (!key) {
        console.error('No hay key de imagen para obtener');
        return;
      }

      const presignedUrl = await getUserProfileImage(key);
      if (!presignedUrl) {
        console.error('No se pudo obtener la URL de la imagen');
        return;
      }

      setImagePreview(presignedUrl);
    } catch (error) {
      console.error('Error al obtener la imagen de perfil:', error);
      Swal.fire({
        title: '¡Error!',
        text: 'No se pudo cargar la imagen de perfil',
        icon: 'error',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  useEffect(() => {
    if (!id) return;

    handleGetUserById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    positions,
    isEditMode,
    imagePreview,
    control,
    data,
    user,
    watch,
    register,
    handleUploadImage,
    handleImageChange,
    handleSubmit,
    setValueData,
    onSubmit,
    activeTab,
    setActiveTab,
  }
}

export default useUserEdit
