import * as yup from "yup";

const useUserSchema = () => {
  const createUserSchema = yup.object({

    profile_picture: yup.mixed()
      .required("La foto de perfil es requerida")
      .test('is-file', 'Por favor, sube una imagen de perfil', (value) => {
        // Comprueba si hay un archivo (File) o una URL de imagen (String)
        return value && (value instanceof File || typeof value === 'string');
      }),

    id: yup.string()
      .required("El número de identificación es requerido")
      .matches(/^\d+$/, "El número de identificación debe contener solo dígitos"),

    hire_date: yup.mixed()
      .required("La fecha de contratación es requerida")
      .test('is-date', 'Debe ser una fecha válida', (value) => {
        return value && (value instanceof Date || typeof value === 'string' || value.isValid);
      }),

    first_name: yup.string()
      .required("El nombre es requerido")
      .min(2, "El nombre debe tener al menos 2 caracteres"),

    last_name: yup.string()
      .required("El apellido es requerido")
      .min(2, "El apellido debe tener al menos 2 caracteres"),

    email: yup.string()
      .nullable()
      .email("Ingrese un correo electrónico válido"),

    personal_email: yup.string()
      .required("El correo electrónico personal es requerido")
      .email("Ingrese un correo electrónico válido"),

    phone: yup.string()
      .required("El teléfono es requerido")
      .matches(/^\d+$/, "El número telefónico debe contener solo dígitos")
      .min(7, "El número debe tener al menos 7 dígitos"),

    immediate_supervisor: yup.mixed()
      .required("El supervisor inmediato es requerido")
      .test('is-valid-supervisor', 'Seleccione un supervisor válido', (value) => {
        return value && (typeof value === 'object' ? !!value.id : true);
      }),

    position: yup.string()
      .required("La posición es requerida"),

    role_id: yup.mixed()
      .required("El rol es requerido"),

    work_location_id: yup.mixed()
      .required("El lugar de trabajo es requerido"),

    address: yup.string()
      .required("La dirección es requerida")
  });

  return {
    createUserSchema
  };
};

export default useUserSchema;