import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import useComponentsControllers from '../../controllers/componentsControllers';

const CustomUserSelect = ({
  value,
  onChange,
  error,
  helperText,
  label = 'Usuarios',
  ...props
}) => {
  const { useUserSelect } = useComponentsControllers();
  const { users, selectedUser, handleSelectedUser } = useUserSelect({ selectedValue: value, onChange });

  return (
    <Autocomplete
      options={users}
      getOptionKey={(user) => user.id}
      value={selectedUser}
      getOptionLabel={(option) => {
        if (!option) return '';
        return `${option.first_name || ''} ${option.last_name || ''}`.trim();
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      sx={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          required={props.required}
        />
      )}
      onChange={(event, newValue) => handleSelectedUser(event, newValue)}
      {...props}
    />
  );
};

export default CustomUserSelect;