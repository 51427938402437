/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useWorkLocationsService from '../../../services/workLocation';

const useWorkLocationSelect = ({ selectedValue = null, onChange }) => {
  const [workLocations, setWorkLocations] = useState([]);
  const [selectedWorkLocation, setSelectedWorkLocation] = useState(null);

  const { getWorkLocations } = useWorkLocationsService();

  const handleSelectedWorkLocation = (event, value) => {
    setSelectedWorkLocation(value);
    onChange?.(value);
  }

  useEffect(() => {
    const hadleGetUsers = async () => {
      const response = await getWorkLocations({ page: -1, positionsPerPage: -1 });
      setWorkLocations(response);
      const workLocation = response.find((workLocation) => workLocation.id === selectedValue);

      if (workLocation) {
        setSelectedWorkLocation(workLocation);
      } else {
        setSelectedWorkLocation(null);
      }
    }

    hadleGetUsers()
  }, [])

  return {
    workLocations,
    selectedWorkLocation,
    handleSelectedWorkLocation
  }
}

export default useWorkLocationSelect;