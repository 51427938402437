import { useState } from "react";
import useUserService from '../../../services/user';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import useUserSchema from '../../../schemas/user';

const useUserCreate = () => {
  const MAX_FILE_SIZE_KB = 1000;
  const navigator = useNavigate();
  const { createUser } = useUserService();
  const { createUserSchema } = useUserSchema();

  const {
    handleSubmit,
    getValues,
    control,
    register,
    watch,
    setValue,
    trigger, // Añadir esto para validar manualmente
    formState: { errors, isValid, isDirty, dirtyFields }
  } = useForm({
    defaultValues: {
      email: null,
      first_name: null,
      hire_date: null,
      id: null,
      immediate_supervisor: null,
      is_active: false,
      last_name: null,
      position: null,
      role_id: null,
      work_location_id: null,
      profile_picture: null,
      address: null,
      personal_email: '',
      phone: null
    },
    resolver: yupResolver(createUserSchema),
    mode: "onChange"
  });

  const [imagePreview, setImagePreview] = useState('');
  const [data, setData] = useState(getValues());
  const [activeTab, setActiveTab] = useState(0);
  const [profilePicture, setProfilePicture] = useState();

  const positions = [
    "ADMINISTRATIVO-PROYECTOS",
    "OPERATIVO",
    "ADMINISTRATIVO",
  ];

  const setValueData = (name, value) => {
    setValue(name, value);
    setData((prev) => ({ ...prev, [name]: value }));
    trigger();
  }

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;


    // Validar tamaño del archivo original
    const fileSizeMB = (file.size / 1024).toFixed(2);
    if (fileSizeMB > MAX_FILE_SIZE_KB) {
      Swal.fire({
        title: '¡Atención!',
        text: `El archivo seleccionado excede el tamaño máximo permitido (1 MB).`,
        icon: 'warning',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
      });
    }

    // Validar que el archivo sea una imagen
    if (!file.type.startsWith('image/')) {
      Swal.fire({
        title: '¡Atención!',
        text: "El archivo seleccionado no es una imagen.",
        icon: 'warning',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    // Crear una previsualización de la imagen
    const reader = new FileReader();
    reader.onload = (event) => {
      setImagePreview(event.target.result);
    };
    reader.readAsDataURL(file);

    setValue("profile_picture", file, { shouldValidate: true, shouldDirty: true });
    setValueData('profile_picture', file);
    setProfilePicture(file);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    data.hire_date = new Date(data?.hire_date)?.toISOString()?.split('T')[0];
    data.is_active = false;
    data.position = data.position.toUpperCase();
    data.profile_picture = imagePreview || data.profile_picture;
    data.role_id = data.role_id.id;
    data.work_location_id = data.work_location_id.id;
    data.immediate_supervisor = data.immediate_supervisor.id;
    data.profile_picture = profilePicture;

    Object.keys(data).forEach(key => {
      if (key === 'profile_picture' && data[key]) {
        formData.append('profile_picture', data[key], data[key].name);
      } else {
        formData.append(key, data[key]);
      }
    });

    const response = await createUser(formData);

    if (response?.status === 201) {
      Swal.fire({
        title: '¡Éxito!',
        text: "Usuario creado correctamente.",
        icon: 'success',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      }).then(() => {
        navigator(`/admin/usuarios/${data.id}`);
      });
    } else {
      Swal.fire({
        title: '¡Error!',
        text: "Hubo un error al crear el usuario.",
        icon: 'error',
        confirmButtonColor: '#6dad48',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  return {
    positions,
    imagePreview,
    errors,
    isValid,
    isDirty,
    control,
    dirtyFields,
    activeTab,
    setActiveTab,
    data,
    watch,
    register,
    setValueData,
    handleImageChange,
    handleSubmit,
    onSubmit,

  }
}

export default useUserCreate
